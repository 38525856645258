import React, { useContext, useEffect, useState } from 'react';
import { CustomerContext } from './customer-context';
import { APIHelperContext } from './api-helper-context';
import StarsIdService from '../services/stars-id-service/stars-id-service';
import GarageLookupService from '../services/garage-lookup-service/garage-lookup-service';
import ConnectedServicesService from '../services/connected-services-service/connected-services-service';

export const ConnectedServicesContext = React.createContext<any>({
  siteCode: null,
  countryCode: null,
});

interface Props {
  children: React.ReactNode;
}

export const ConnectedServicesContextProvider = ({ children }: Props) => {
  const [starsIds, setStarsIds] = useState({});
  const [garage, setGarage] = useState({});
  const [vehicle, _setVehicle] = useState<null | any>(null);
  const [selectedStarsId, setSelectedStarsId] = useState<null | any>(null);
  const [paidSubscriptions, setPaidSubscriptions] = useState<null | any>(null);
  const [freeSubscriptions, setFreeSubscriptions] = useState<null | any>(null);
  const [hasModem, setHasModem] = useState(true);
  const [{ userProfile }] = useContext(CustomerContext);
  const [apiHelper] = useContext(APIHelperContext);

  const clearConnectedServices = () => {
    setPaidSubscriptions(null);
    setFreeSubscriptions(null);
    _setVehicle(null);
    setHasModem(true);
  };

  const refreshGarage = () => {
    setGarage({});
    clearConnectedServices();

    const garageLookupService = new GarageLookupService();
    garageLookupService.request(userProfile.lighthouseGuid, apiHelper).then((response: any) => {
      setGarage(response);
    });
  };

  useEffect(() => {
    if (userProfile) {
      const starsIdService = new StarsIdService();
      starsIdService.request(apiHelper).then((response: any) => {
        setStarsIds(response);
      });

      refreshGarage();
    }
  }, [userProfile]);

  const loadSubscriptionOffers = (vin: string) => {
    const connectedServicesService = new ConnectedServicesService();
    const data = {
      vin: vin,
      partnerCountry: apiHelper.countryCode,
      customerId: userProfile.lighthouseGuid,
      language: apiHelper.languageCountry(),
      state: userProfile.state,
      includeAllRatePlans: true,
      brand: 'Ford',
    };
    setFreeSubscriptions(null);
    setPaidSubscriptions(null);
    setHasModem(true);

    connectedServicesService.request(data, apiHelper).then((response: any) => {
      if (response.status == 'error') {
        setFreeSubscriptions(false);
        setPaidSubscriptions(false);
        return { status: 'error' };
      }

      setFreeSubscriptions(response.freeServices);
      setPaidSubscriptions(response.paidServices);

      if (!response.modemAvailable) setHasModem(true);
      else if (response.modemAuthorized && response.userAuthorized) setHasModem(true);
      else setHasModem(false);
    });
  };

  const setVehicle = value => {
    _setVehicle(value);
    setPaidSubscriptions(null);
    setFreeSubscriptions(null);
  };

  return (
    <ConnectedServicesContext.Provider
      value={[
        {
          starsIds,
          garage,
          refreshGarage,
          paidSubscriptions,
          freeSubscriptions,
          loadSubscriptionOffers,
          vehicle,
          setVehicle,
          selectedStarsId,
          setSelectedStarsId,
          clearConnectedServices,
          hasModem,
        },
      ]}
    >
      {children}
    </ConnectedServicesContext.Provider>
  );
};
