export const IC_API_PATH = {
  SEARCH: '/search',
  DEALER_TRANSACTIONS: '/api/v2/dealer-combined-transactions',
  DEALER_TRANSACTIONS2: '/api/v2/dealer-transactions',
  OFFERS: '/private-offer',
  REWARD_CATALOG: '/v1/customers/{modifier}/catalog',
  GOODWILL_POINTS: '/good-will-points',
  DEALER_DETAILS: '/dealer-info',
  REDEEM_OFFER: '/private-offer',
  REPORT_DOWNLOAD: '/files/{modifier}',
  REDEEM_SERVICE_OFFER: '/v1/customers/{modifier}/redeem/serviceWithAttachments',
  REDEEM_SALES_OFFER: '/v1/customers/{modifier}/redeem/vehicle-purchase-with-attachments',
  DEFAULT_POINTS_QUERY: '/rewards-account-info/customers/txnSearch',
  REWARDS_HISTORY: '/rewards-account-info/customers/txnSearch',
};

export const AEM_API_PATH = {
  DEALER_ALERT: 'dealer-alert',
};

export const ADMIN_API_PATH = {
  ADMIN_VALIDATION: '/valid-cdsid',
};

export const DEFAULT_LANGUAGE_CODE = 'en';
export const DEFAULT_COUNTRY_CODE = 'us';
export const DEFAULT_REGION_CODE = 'us';

export const LANGUAGE_CODE_EN_CA = `en-${DEFAULT_REGION_CODE}`;
export const LANGUAGE_CODE_FR_CA = `fr-${DEFAULT_REGION_CODE}`;
