import React from 'react';
import cx from '../../../utils/classnames';

import styles from './tabbar.module.scss';

interface Props {
  children: React.ReactNode;
  id?: string;
  active?: boolean;
  setTab?(id: string): void;
  className?: string;
}

function Tab({ children, active, setTab, id, className }: Props) {
  return (
    <button
      role='tab'
      className={cx(styles.tab, { [styles.active]: active }, className)}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        setTab(id);
      }}
      aria-selected={active}
      //aria-controls={`tab-panel-${id}`} -- Ask matt to review - removed because it doesn't control anything specific
      aria-label={`tab panel ${children}`}
    >
      {active ? <h1>{children}</h1> : children}
    </button>
  );
}

export default Tab;
