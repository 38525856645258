import amplitude from 'amplitude-js';
import React, { useEffect, useState } from 'react';
import AdminService from '../services/auth-service/admin-service';
import AuthService from '../services/auth-service/auth-service';

export const AuthContext = React.createContext<any>({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  isDealer: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  getUserProfile: () => ({}),
  isAdmin: false,
});

interface Props {
  children: React.ReactNode;
}
export const AuthContextProvider = ({ children }: Props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminAuthenticating, setAdminAuthenticating] = useState(true);

  const authService = new AuthService();
  const adminService = new AdminService();

  useEffect(() => {
    const { isAuthenticated, isDealer } = authService;
    if (isAuthenticated() && !isDealer()) {
      adminService
        .validateAdminUser()
        .then(admin => {
          setIsAdmin(admin);
          setAdminAuthenticating(false);
        })
        .catch(error => {
          amplitude.getInstance().logEvent('pfs AuthContext fail', { error });
          setIsAdmin(false);
          setAdminAuthenticating(false);
        });
    } else {
      setAdminAuthenticating(false);
    }
  }, []);

  const isLocal = window.location.host.includes('local') || window.location.search.includes('admin=override');
  const isQA = window.location.host.includes('wwwqa') || window.location.search.includes('admin=override');

  return (
    <AuthContext.Provider
      value={{
        authService,
        isAdmin,
        isDealer: authService.isDealer,
        isOk2Submit: authService.isDealer() || isLocal || isQA,
        adminAuthenticating,
        isLocal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
