import React, { useState } from 'react';
import { CtaButton } from '../../common/cta/cta';
import { Body, Buttons, ModalDialog } from '../../common/modal-dialog/modal-dialog';

const DEALER_ACKNOWLEDGE_DIALOG = 'DEALER_ACKNOWLEDGE_DIALOG';

export const DealerAcknowledgeDialog = () => {
  const [show, setShow] = useState(sessionStorage.getItem(DEALER_ACKNOWLEDGE_DIALOG) != 'true');

  const setShowDialog = () => {
    setShow(false);
    sessionStorage.setItem(DEALER_ACKNOWLEDGE_DIALOG, 'true');
  };
  return (
    <>
      {show && (
        <ModalDialog title='Dealer Acknowledgment' width='30vw'>
          <Body>
            <div>Dealer acknowledges that the data within the Member Portal is only to be used for processing Rewards transactions.</div>
          </Body>
          <Buttons>
            <CtaButton label='I Accept' onClick={setShowDialog} />
          </Buttons>
        </ModalDialog>
      )}
    </>
  );
};
