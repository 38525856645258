import React, { useContext, useState } from 'react';
import utils from '../../../utils/utils.module.scss';
import { RewardProgramChoice } from '../../sections/choice-reward-program/choice-reward-program';
import { Header } from '../../common/header/header';
import { CtaButton } from '../../common/cta/cta';
import { TranslationContext } from '../../../context/translations-context';
import ReportDownloadService, { REPORT_TYPE } from '../../../services/report-download-service/report-download-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import { CustomerContext } from '../../../context/customer-context';
import { SiteContext } from '../../../context/site-context';

export const TabReportsGoodwill = () => {
  const [{ t, language }] = useContext(TranslationContext);
  const [apiHelper] = useContext(APIHelperContext);
  const [{ rewardProgram }] = useContext(CustomerContext);
  const [{ countryCode, isCanada }] = useContext(SiteContext);
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const [year, setYear] = useState(thisYear);
  const [month, setMonth] = useState(1);
  const years = [2019];
  while (years[0] < thisYear) years.unshift(years[0] + 1);
  const months =
    year === thisYear
      ? Array.from({ length: 12 }, (e, i) => new Date(null, i + 1, null).toLocaleDateString(language, { month: 'long' })).slice(0, thisMonth + 1)
      : Array.from({ length: 12 }, (e, i) => new Date(null, i + 1, null).toLocaleDateString(language, { month: 'long' }));

  const download = () => {
    const reportDownloadService = new ReportDownloadService();
    const filename = `${t(REPORT_TYPE.DealerGoodwill)}_${rewardProgram}_${apiHelper.siteCode}_${year}_${String(month).padStart(
      2,
      '0'
    )}_${language.substr(0, 2)}.xlsx`;

    const filepath = `reports/DealerGoodwill${isCanada() ? `${countryCode[0]}${countryCode[1].toLowerCase()}` : ''}/${filename}`;
    reportDownloadService.downloadReport(filepath, apiHelper, t('REPORT_NOT_FOUND_FOR_MONTH_AND_YEAR') + ': ' + months[month - 1] + ', ' + year);
  };
  return (
    <div>
      <RewardProgramChoice uniqueID='RewardProgramChoice' checkEligibility={false} />
      <Header title={t('YEAR')} className={utils.section}>
        <select name='years' className={utils.combo} onChange={e => setYear(+e.target.value)}>
          {years.map((y: number) => (
            <option key={y}>{y}</option>
          ))}
        </select>
      </Header>
      <Header title={t('MONTH')} className={utils.section}>
        <select name='months' className={utils.combo} onChange={e => setMonth(+e.target.value)}>
          {months.map((monthName, i) => (
            <option key={monthName} value={i + 1} selected={i + 1 === month}>
              {monthName}
            </option>
          ))}
        </select>
      </Header>

      <CtaButton label={t('Download Report')} className={utils.max300} onClick={download} />
    </div>
  );
};
