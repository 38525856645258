/* eslint-disable no-useless-escape */
import React from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import { FontAwesome } from '../font-awesome/font-awesome';

interface Props {
  text: string;
  className?: string;
}

export function ErrorText({ className, text }: Props) {
  return (
    <>
      {text ? (
        <div className={cx(utils.error, className)} data-testid='text'>
          <FontAwesome icon='exclamation-triangle' />
          {text}
        </div>
      ) : null}
    </>
  );
}
