import amplitude from 'amplitude-js';
import Axios from 'axios';
import { AEM_API_PATH, IC_API_PATH } from '../constants';
import { APIHelper } from '../models/api-helper';
import { buildQueryParams } from '../utils/url.utils';
import ServiceHandler from './service-handler';

const axios = Axios.create();

export const post = async (service: string, guid: string, params: any, data: any, headers: any, apiHelper: APIHelper) => {
  const cfg = ServiceHandler.ConfigService;
  const queryParams = buildQueryParams({
    language: apiHelper.languageCountry(),
    partnerCountry: apiHelper.countryCode,
    siteCode: apiHelper.siteCode,
    ...params,
  });
  const url = cfg.LPS_BASE_URL + IC_API_PATH[service].replace('{modifier}', guid) + queryParams;
  let response;
  try {
    response = await axios.post(url, data, {
      headers: {
        Authorization: ServiceHandler.AuthenticationService.getIdToken(),
        ...headers,
      },
    });

    if (!response || response.data?.error) amplitude.getInstance().logEvent('pfs post error', { response, guid });

    return response.data;
  } catch (error) {
    apiHelper.handleError(error, service, JSON.stringify(response || ''), guid);
    return { status: 'error', data: error ? error?.response?.data : 'unknown' };
  }
};

export const put = async (service: string, guid: string, params: any, data: any, apiHelper: APIHelper) => {
  const cfg = ServiceHandler.ConfigService;
  const queryParams = buildQueryParams({
    language: apiHelper.languageCountry(),
    partnerCountry: apiHelper.countryCode,
    siteCode: apiHelper.siteCode,
    ...params,
  });
  const url = cfg.LPS_BASE_URL + IC_API_PATH[service].replace('{modifier}', guid) + queryParams;
  let response;
  try {
    response = await axios.put(url, data, {
      headers: {
        Authorization: ServiceHandler.AuthenticationService.getIdToken(),
      },
    });
    if (!response || response.data?.error) amplitude.getInstance().logEvent('pfs put error', { response, guid });

    return response.data;
  } catch (error) {
    apiHelper.handleError(error, service, JSON.stringify(response || ''));
    return { status: 'error', error };
  }
};

export const get = async (service: string, guid: string, params: any, apiHelper: APIHelper, responseType = '', report = '') => {
  const cfg = ServiceHandler.ConfigService;
  const queryParams = buildQueryParams({
    language: apiHelper.languageCountry(),
    partnerCountry: apiHelper.countryCode,
    siteCode: apiHelper.siteCode,
    ...params,
  });

  const url = cfg.LPS_BASE_URL + IC_API_PATH[service].replace('{modifier}', guid) + queryParams + report;
  let response;
  try {
    response = await axios.get(url, {
      responseType: responseType == 'blob' ? 'blob' : 'json',
      headers: {
        Authorization: ServiceHandler.AuthenticationService.getIdToken(),
      },
    });
    if (!response || response.data?.error) amplitude.getInstance().logEvent('pfs get error', { response, guid });

    return response.data;
  } catch (error) {
    apiHelper.handleError(error, service, JSON.stringify(response || ''), guid);
    return { status: 'error', service };
  }
};

export const download = async (service: string, params: any, apiHelper: APIHelper) => {
  const cfg = ServiceHandler.ConfigService;
  const queryParams = buildQueryParams({
    language: apiHelper.languageCountry(),
    partnerCountry: apiHelper.countryCode,
    siteCode: apiHelper.siteCode,
    ...params,
  });
  const url = cfg.LPS_BASE_URL + IC_API_PATH[service] + queryParams;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: ServiceHandler.AuthenticationService.getIdToken(),
      },
    });

    return response.data;
  } catch (error) {
    apiHelper.handleError(error, service);
    return { status: 'error', service };
  }
};

export const get_aem = async (service: string, apiHelper: APIHelper) => {
  const cfg = ServiceHandler.ConfigService;
  const url = `${cfg.AEM_BASE_URL}/content/dam/loyalty/pfs/${apiHelper.countryCode.substr(0, 2).toLowerCase()}/${apiHelper.language}/${
    AEM_API_PATH[service]
  }.loyalty-model.json`;

  try {
    const response = await axios.get(url);
    const data: any = {};
    response.data.elements.forEach(e => (data[e.title] = e.value));
    return data;
  } catch (error) {
    apiHelper.handleError(error, service);
    return { status: 'error', service };
  }
};

export const get_dictionaries = async (apiHelper: APIHelper) => {
  const cfg = ServiceHandler.ConfigService;

  const url = `${
    cfg.AEM_BASE_URL
  }/content/dictionaries/i18n/appreciation/FPRCouponsandOffers/${apiHelper.language.toLowerCase()}_${apiHelper.countryCode
    .substr(0, 2)
    .toLowerCase()}.json`;

  try {
    const response = await axios.get(url);

    return Object.keys(response.data)
      .filter(key => key.endsWith('.couponName'))
      .reduce((obj, key) => {
        obj[key.replace('.couponName', '')] = response.data[key];
        return obj;
      }, {});
  } catch (error) {
    apiHelper.handleError(error, 'AEM dictionaries');
    return { status: 'error', service: 'AEM dictionaries' };
  }
};
