// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jo_RqH18YAXBwXP3BjI1{font-weight:900;font-size:20px;margin:5px 0;margin-bottom:30px}.jo_RqH18YAXBwXP3BjI1 i{margin-right:10px;color:#5b616b;font-size:16px;transform:translateY(-3px)}`, "",{"version":3,"sources":["webpack://./src/components/common/header/header.module.scss","webpack://./src/styles/theme/_colors.scss"],"names":[],"mappings":"AACA,sBACE,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CAGA,wBACE,iBAAA,CACA,aCMS,CDLT,cAAA,CACA,0BAAA","sourcesContent":["@import \"main.scss\";\n.header {\n  font-weight: 900;\n  font-size: 20px;\n  margin: 5px 0;\n  margin-bottom: 30px;\n  // border-bottom: solid 1px #ccc;\n\n  i {\n    margin-right: 10px;\n    color: $label-text;\n    font-size: 16px;\n    transform: translateY(-3px);\n  }\n}\n","// colors\n$brand-primary: #0266a3;\n$brand-dark-cyan: #0276b3;\n$brand-light-cyan: #00a9e0;\n$brand-dark-cyan-opacity: rgba(4, 120, 179, 0.9);\n$brand-primary-dark: #02234d;\n\n$brand-gray-text: #333333;\n$brand-text: $brand-gray-text;\n$brand-inactive-text: #636363;\n$brand-inactive-ui: #a3a3a3;\n$brand-light-grey: #e3e3e3;\n$brand-ui-light: #efefef;\n$brand-background: #f7f7f7;\n$brand-white: #ffffff;\n\n$label-text: #5b616b;\n\n$brand-alert-green: #12b664;\n$brand-alert-orange: #e58b00;\n$brand-alert-red: #d00c1b;\n\n// exports\n$theme-text-dark: $brand-gray-text;\n$theme-text-light: $brand-white;\n$theme-text-primary: $brand-primary;\n\n$theme-primary: $brand-primary;\n$theme-secondary: $brand-white;\n$theme-indicator: $brand-dark-cyan;\n\n$theme-background-light: $brand-background;\n$theme-background-dark: $brand-dark-cyan;\n$theme-background-primary-dark: $brand-primary-dark;\n$theme-background-light-grey: $brand-light-grey;\n\n$theme-link-primary: $brand-dark-cyan;\n$theme-link-primary-opacity: $brand-dark-cyan-opacity;\n\n$theme-loader-primary: $brand-dark-cyan;\n$theme-loader-secondary: $brand-light-cyan;\n$theme-loader-white: $brand-white;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `jo_RqH18YAXBwXP3BjI1`
};
export default ___CSS_LOADER_EXPORT___;
