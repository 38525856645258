export function buildQueryParams(params: any) {
  const qs: string[] = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
    }
  }
  return `?${qs.join('&')}`;
}

export function encryptFormData(formdata: FormData) {
  return null;
  const fd = {};
  formdata.forEach((value, key) => (fd[key] = value));
  return btoa(btoa(JSON.stringify(fd)));
}
