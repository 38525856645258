import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import cx from '../../../utils/classnames';

import styles from './terms-not-accepted.module.scss';

interface Props {
  program: string;
}
export const TermsNotAccepted = ({ program }: Props) => {
  const [{ t }] = useContext(TranslationContext);
  return (
    <div className={styles.wrapper}>
      <div>
        <i className={cx(`fas fa-info-circle`)}></i>
        {t('NOT_MEMBER').replace('{{type}}', t(program))}
      </div>
    </div>
  );
};
