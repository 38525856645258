import React from 'react';
import utils from '../../../utils/utils.module.scss';

interface Props {
  title: string;
  width?: string;
  height?: string;
  children: any;
  doClose?(): void;
}
interface ChildProps {
  children: React.ReactElement[] | React.ReactElement;
}
export const Body = ({ children }: ChildProps) => <>{children}</>;
export const Buttons = ({ children }: ChildProps) => (
  <div className={utils.modalButtons}>
    <div style={{ order: 0 }} />
    {children}
  </div>
);

export const ModalDialog = ({ title, width, height, doClose, children }: Props) => {
  return (
    <>
      <div className={utils.overlay}></div>
      <div className={utils.overlayPopupContainer}>
        <div className={utils.overlayPopup} style={{ width: width, height: height }}>
          <div className={utils.overlayTitle}>
            {title}
            {doClose && <span onClick={doClose}>x</span>}
          </div>
          <div className={utils.overlayCopy}>
            <div className={utils.modalBody}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
