import React, { useContext, useEffect, useState } from 'react';
import DealerDetailsService from '../services/dealer-details-service/dealer-details-service';
import { APIHelperContext } from './api-helper-context';
import { TAB_STATES } from '../views/member-view/member-view';
import amplitude from 'amplitude-js';
import { AuthContext } from './auth-context';

export const DealerContext = React.createContext<any>({
  message: '',
});

const sales = [
  'ACCTNT',
  'ADFCMG',
  'AOFCMG',
  'ASLSMG',
  'BDCMGR',
  'BUSMGR',
  'CDTMGR',
  'CONTRL',
  'CRMGR',
  'DEALER',
  'DGTMKT',
  'GENMGR',
  'INTCRD',
  'OFCMGR',
  'SLSCLTFV',
  'SLSCLT',
  'SLSMGR',
  'SMEGSM',
];

const service = ['ASVCMG', 'CASHR', 'CTSADV', 'PTSSDR', 'QLNCSR', 'QLNMGR', 'SVCADV', 'SVCMGR', 'WRNCLK'];
const parts = ['PTSCTR', 'PTSCTS', 'PTSGEN', 'PTSMGR', 'PTSMNGR'];
const fAndI = ['F&IMGR'];

interface Props {
  children: React.ReactNode;
}

export const DealerContextProvider = ({ children }: Props) => {
  const [dealerDetails, setDealerDetails] = useState(null);
  const [dealerPaCodes, setDealerPaCodes] = useState([]);
  const [retry, setRetry] = useState(false);
  const [defaultTab, setDefaultTab] = useState(TAB_STATES.TAB_SALES as string);
  const [apiHelper] = useContext(APIHelperContext);
  const { isDealer } = useContext(AuthContext);
  const authenticated = isDealer();

  useEffect(() => {
    if (authenticated && apiHelper.siteCode && !retry) {
      window.setTimeout(() => {
        const dealerDetailsService = new DealerDetailsService();
        dealerDetailsService.request(apiHelper).then((details: any) => {
          setDealerDetails(details);
          const dealer = details?.dealer;

          if (!dealer) {
            amplitude.getInstance().logEvent('pfs dealerdetails error', { dealerDetails, details, retry });
            setRetry(true);
          } else {
            if (sales.includes(details.role) || fAndI.includes(details.role)) setDefaultTab(TAB_STATES.TAB_SALES as any);
            if (service.includes(details.role) || parts.includes(details.role)) setDefaultTab(TAB_STATES.TAB_SERVICE as any);
            // if (parts.includes(details.role)) setDefaultTab(TAB_STATES.TAB_PARTS as any);
            // if (fAndI.includes(details.role)) setDefaultTab(TAB_STATES.TAB_FANDI as any);

            setDealerPaCodes([
              { code: dealer.paCode },
              ...(dealer.secondaryDealers || []).map(code => ({
                code: code,
              })),
            ]);
          }
        });
      }, 200);

      // const intervalId = setInterval(() => {
      //   const dealerDetailsService = new DealerDetailsService();
      //   dealerDetailsService.request(apiHelper);
      // }, 1000 * 60 * 60);

      // return () => clearInterval(intervalId);
    }
  }, [apiHelper.siteCode, retry]);

  return <DealerContext.Provider value={[{ dealerDetails, dealerPaCodes, defaultTab }]}>{children}</DealerContext.Provider>;
};
